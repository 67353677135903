import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-filter-header',
  templateUrl: './filter-header.component.html',
  styleUrls: ['./filter-header.component.scss']
})
export class FilterHeaderComponent implements OnInit {

  @Input() headerType: any;
  @Input() searchFilters: any;
  @Input() filterSegments: any;
  @Input() section: any;
  @Input() helperData: any;
  @Input() isDisabledApply: boolean;

  constructor() { }

  ngOnInit(): void {
  }

}
