import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { Component, OnInit, Input } from '@angular/core';
import { AppDataService } from 'src/app/app-data.service';

@Component({
  selector: 'app-filter-header-members',
  templateUrl: './filter-header-members.component.html',
  styleUrls: ['./filter-header-members.component.scss']
})
export class FilterHeaderMembersComponent implements OnInit {

  @Input() searchFilters: any;
  @Input() filterSegments: any;
  @Input() filter: any;
  @Input() isDisabledApply: boolean;
  faSpinner = faSpinner;

  constructor(
    public appDataService: AppDataService
  ) { }

  ngOnInit(): void {
  }

}
