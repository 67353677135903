<app-filter-header-members
  *ngIf="headerType === 'member'"
  [(searchFilters)]="searchFilters"
  [(filterSegments)]="filterSegments"
  [isDisabledApply]="isDisabledApply"
></app-filter-header-members>

<app-filter-header-users
  *ngIf="headerType === 'users'"
  [(searchFilters)]="searchFilters"
  [(filterSegments)]="filterSegments"
></app-filter-header-users>

<app-filter-header-activity-tab
  *ngIf="headerType === 'activity'"
  [(searchFilters)]="searchFilters"
  [(selectedActivitiesCount)]="helperData"
></app-filter-header-activity-tab>

<app-filter-header-generic
  *ngIf="headerType === 'generic'"
  [(searchFilters)]="searchFilters"
></app-filter-header-generic>
