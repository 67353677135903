<ng-container *ngIf ="searchFilters">
  <form name="{{searchFilters.formName}}" (submit)="searchFilters.applyFilters()">
    <div class="row col-md-12">
        <div [ngClass]="headerType !== 'generic' ? 'col-md-12' : 'col-md-10'"
        [hidden]="!searchFilters.showSearchFilters && searchFilters.showSearchFilters !== undefined">
        <div [ngClass]="headerType !== 'generic' ? 'row seven-cols' : 'row search-filter-row'" class="search-filter-flex-row">
          <app-filter-header class="full-width" [(helperData)]="helperData" [(searchFilters)]="searchFilters"
            [(filterSegments)]="filterSegments" [(headerType)]="headerType" [isDisabledApply]="isDisabledSearchMember"></app-filter-header>
          <!-- TODO: implement trackby: key -->
          <span class="full-width" *ngFor="let section of searchFilters.sections; let sIndex = index">
            <div class = "row">
                <app-filter-expander [(section)]="searchFilters.sections[sIndex]"></app-filter-expander>
            </div>
              <div class="d-flex flex-wrap">
                  <div *ngFor="let filter of searchFilters.filters; let fIndex = index" class="mb-2">
                      <div style="width: fit-content;" *ngIf="searchFilters.filters[fIndex].section.key === searchFilters.sections[sIndex].key">
                        <app-segment-filter  custom-class="filter.inputClass" (filterSegments)="filterSegmentsHandler($event)"
                          (inputChangeEmitter)="inputChangeHandler()"
                          *ngIf="filter.inputType === 'segment-filter' &amp;&amp; !segmentsDisabled"></app-segment-filter>

                        <app-filter-select *ngIf="(searchFilters.filters[fIndex].type === 'array' &&
                          searchFilters.filters[fIndex].inputType === 'select')" [(section)]="searchFilters.sections[sIndex]"
                          [(filter)]="searchFilters.filters[fIndex]" (inputChangeEmitter)="inputChangeHandler()"></app-filter-select>

                        <app-filter-typeahead *ngIf="(searchFilters.filters[fIndex].type === 'string' &&
                          searchFilters.filters[fIndex].inputType === 'typeahead')" [(section)]="searchFilters.sections[sIndex]"
                          [(filter)]="searchFilters.filters[fIndex]" (inputChangeEmitter)="inputChangeHandler()"></app-filter-typeahead>

                        <app-filter-dropdown *ngIf="(searchFilters.filters[fIndex].type === 'array' &&
                          searchFilters.filters[fIndex].inputType === 'dropdown')" [(section)]="searchFilters.sections[sIndex]"
                          [(filter)]="searchFilters.filters[fIndex]" (inputChangeEmitter)="inputChangeHandler()"></app-filter-dropdown>

                        <app-filter-datepicker *ngIf="searchFilters.filters[fIndex].type === 'date'"
                          [(section)]="searchFilters.sections[sIndex]" [(filter)]="searchFilters.filters[fIndex]"
                          (inputChangeEmitter)="inputChangeHandler()"></app-filter-datepicker>

                        <app-filter-string *ngIf="(!searchFilters.filters[fIndex].type || searchFilters.filters[fIndex].type === 'string')"
                          [(section)]="searchFilters.sections[sIndex]" [(filter)]="searchFilters.filters[fIndex]"
                          (inputChangeEmitter)="inputChangeHandler()" class="ng-scope ng-isolate-scope"></app-filter-string>

                        <app-filter-number *ngIf="searchFilters.filters[fIndex].type === 'number' "
                          [(section)]="searchFilters.sections[sIndex]" [(filter)]="searchFilters.filters[fIndex]"
                          (inputChangeEmitter)="inputChangeHandler($event)"></app-filter-number>

                        <app-filter-checkbox *ngIf="searchFilters.filters[fIndex].type === 'boolean'"
                          [(section)]="searchFilters.sections[sIndex]" [(filter)]="searchFilters.filters[fIndex]"
                          (inputChangeEmitter)="inputChangeHandler($event)"></app-filter-checkbox>

                        <app-filter-radio [(section)]="searchFilters.sections[sIndex]" [(filter)]="searchFilters.filters[fIndex]"
                          (inputChangeEmitter)="inputChangeHandler($event)" *ngIf="searchFilters.filters[fIndex].type === 'radio'"
                          [ngClass]="{'filter-radio-width': filter.linebreak}" class="filter-radio"></app-filter-radio>
                      </div>
                  </div>
              </div>
          </span>
          <div class="row m-b-sm m-t-sm full-width" [ngClass]="headerType !== 'memberSearch' ? 'search-filter-row' : ''" *ngIf="searchFilters.showSearchFilters">
            <app-filter-tags class="full-width" [(searchFilters)]="searchFilters" [(helpers)]="helpers" [(appliedFilters)]="appliedFilters" [(someFiltersNotApplied)]="someFiltersNotApplied"></app-filter-tags>
          </div>
        </div>
      </div>
      <div class="col-md-2 float-right" *ngIf="headerType === 'generic'">
        <app-filter-button-panel [(searchFilters)]="searchFilters"></app-filter-button-panel>
      </div>
    </div>
  </form>
</ng-container>
